import React, { ReactElement } from 'react';

// Components
import { FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, Calendar } from 'templates';

// GraphQL
import { useMeetsQuery } from 'graphql';

//  Types
import { IFilter, SORT_BY } from 'types';

const FILTERS: IFilter[] = [
  { title: 'Season', gqlKey: 'season', hasAllOption: false },
  { title: 'Year', gqlKey: 'year', isMostRecent: true },
];

const title = 'Calendar/Results';

const CalendarPage = (): ReactElement => {
  const { meets } = useMeetsQuery();

  return (
    <SEOContent title={title}>
      <PageLayout>
        <BasicTemplate templateTitle={title}>
          <FilterBar
            data={meets}
            filters={FILTERS}
            render={(tableData) => <Calendar data={tableData} />}
            sortBy={SORT_BY.ASC}
          />
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default CalendarPage;
